<template>
  <!-- :style="bdList.length == 0?'background-color: #fff;':''" -->
  <div id="box" v-if="isReload">
    <div class="top">
      <div class="avator">
        <div class="avator-img">
          <img :src="user.headimg" alt />
          <div class="empInfo">
            <p class="empname">{{user.empname}}</p>
            <p class="empno">工号：{{user.empno}}</p>
          </div>
        </div>
        <div class="avator-top">
          <i
            class="iconfont iconshezhi"
            style="font-size: 0.5rem;"
            @click="$router.push('/xsqdsetting')"
          ></i>
        </div>
      </div>
    </div>
    <div class="qd">
      <div class="qdPremium">
        <div class="premiumTime" @click="isDatePickerShow = true">
          {{datePickerValue | datefiler }}
          <img class="itemIcon" src="@/assets/newskin/xia.png" alt />
        </div>
        <div class="premium">
          <div class="premiumCon">
            <div class="premiumAmount">{{ monthAmount | sumfiler }}</div>
            <div class="textTip">本月保费(元)</div>
          </div>
          <div class="separate"></div>
          <div class="premiumCon">
            <div class="premiumAmount">{{ allAmount | sumfiler }}</div>
            <div class="textTip">累计保费(元)</div>
          </div>
        </div>
      </div>
      <div class="qdOrder" v-for="(item,index) in viewlist" :key="index">
        <div class="qdOrderTitle">{{item.title}}</div>
        <div class="qdOrderCon">
          <div
            class="qdOrderItem"
            v-for="(subitem,subindex) in item.list"
            :key="subindex"
            @click="jumpClick(subitem.path)"
          >
            <img :src="subitem.imgUrl" alt />
            <div class="textName">{{subitem.text}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <ul>
        <router-link tag="li" to="/main">
          <i class="hotSall heightStyle"></i>
          <span>热销</span>
        </router-link>
        <router-link tag="li" to="/Class">
          <i class="product heightStyle"></i>
          <span>产品库</span>
        </router-link>
        <router-link tag="li" to="/UserInfo">
          <i class="mine heightStyle"></i>
          <span style="color:rgba(255, 115, 38, 1)">我的</span>
        </router-link>
      </ul>
    </div>
    <md-date-picker
      v-model="isDatePickerShow"
      ref="datePicker"
      type="custom"
      :custom-types="['yyyy', 'MM']"
      :minDate="minDate"
      :maxDate="maxDate"
      :default-date="currentDate"
      large-radius
      class="selectTime"
      @confirm="onDatePickerConfirm"
    ></md-date-picker>
  </div>
</template>
<script>
import { getInfo } from "@/api/xsqd/index";
import { getStorage } from "@/lib/util";
import { Toast } from "mand-mobile";
const colorMap = {
  1: "#34C72C",
  0: "#FEA406",
  3: "#ff0000"
};
export default {
  data() {
    return {
      info: {},
      temp: [],
      // bdList: [],
      user: {},
      allAmount: "",
      monthAmount: "",
      isReload: true,
      isFinished: true,
      colorMap,
      viewlist: [
        {
          title: "我的订单",
          list: [
            {
              imgUrl: require("@/assets/xsqd/allOrder.png"),
              text: "全部订单",
              path: "myOrder?ddstatus=3"
            },
            {
              imgUrl: require("@/assets/xsqd/prendingOrder.png"),
              text: "待处理",
              path: "myOrder?ddstatus=0"
            },
            {
              imgUrl: require("@/assets/xsqd/surrenderOrder.png"),
              text: "已退保",
              path: "myOrder?ddstatus=2"
            },
            {
              imgUrl: require("@/assets/xsqd/completeOrder.png"),
              text: "已完成",
              path: "myOrder?ddstatus=1"
            },
            {
              imgUrl: require("@/assets/xsqd/ic_xq.png"),
              text: "续期保单",
              path: "renewal"
            },
            {
              imgUrl: require("@/assets/xsqd/ic_hb.png"),
              text: "核保",
              path: "underwriting?pageType=1"
            }
          ]
        },
        {
          title: "我的服务",
          list: [
            {
              imgUrl: require("@/assets/xsqd/ic_pd.png"),
              text: "批单",
              path: "pdOrder?orderType=3"
            },
            {
              imgUrl: require("@/assets/xsqd/ic_fpsq.png"),
              text: "发票申请",
              path: "orderStatus?pageType=3"
            },
            {
              imgUrl: require("@/assets/xsqd/ic-tb.png"),
              text: "退保",
              path: "surrender?pageType=2"
            }
          ]
        }
      ],
      isDatePickerShow: false,
      minDate: new Date("2013/9/9"),
      maxDate: new Date(),
      currentDate: new Date(),
      customTypes: ["yyyy", "MM"],
      datePickerValue: new Date(),
      httptype: "",
      allowed: ""
    };
  },
  created() {
    this.httptype = window.location.hostname.split(".")[0];
    this.user = getStorage("u_s", {});
    this.getUserInfo();
    if(sessionStorage.getItem("sessionData")){
      sessionStorage.removeItem("sessionData")
    }
    if (sessionStorage.getItem("ddstatus")) {
      sessionStorage.removeItem("ddstatus");
    }
    if (sessionStorage.getItem("productType")) {
      sessionStorage.removeItem("productType");
    }
    if (sessionStorage.getItem("statusType")) {
      sessionStorage.removeItem("statusType");
    }
  },
  methods: {
    // 个人信息
    getUserInfo() {
      this.isReload = false;
      this.$nextTick(() => {
        this.isReload = true;
      });
      getInfo({
        salecomid: this.user.salecomid,
        saleempno: this.user.empno,
        date: this.timefiler(this.datePickerValue)
      }).then(res => {
        let mes = res.data.data;
        this.info = mes;
        this.monthAmount = mes.allmount;
        this.allAmount = mes.totalMount;
        this.allowed = mes.allowed;
        if (this.allowed === 0) {
          //渠道
          this.viewlist = this.viewlist.splice(0, 1);
          this.viewlist = this.viewlist.map(v => {
            v.list = v.list.splice(0, v.list.length - 1);
            return v;
          });
        }
      });
    },
    //点击跳转
    jumpClick(path) {
      this.$router.push(path);
    },
    //确定获取的时间
    onDatePickerConfirm() {
      this.isDatePickerShow = false;
      this.datePickerValue = this.$refs.datePicker.getFormatDate("yyyy-MM");
      this.getUserInfo();
    },
    //时间过滤器
    timefiler(time) {
      if (!time) return "";
      let date = new Date(time);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      return y + "-" + MM;
    }
  },
  filters: {
    //金额过滤器
    sumfiler(amount) {
      if (!amount) return "0.00";
      var intPart = String(amount);
      var intPartFormat = intPart.replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      var floatPart = ".00";
      var value2Array = intPart.split(".");
      if (value2Array.length === 2) {
        floatPart = value2Array[1].toString(); //拿到小数部分
        var numPart = value2Array[0].toString(); //拿到整数部分
        numPart = numPart.replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        if (floatPart.length === 1) {
          return numPart + "." + floatPart + "0"; //补0
        } else if (floatPart.length > 2) {
          return numPart + "." + floatPart.slice(0, 2); //一旦超过2位  截取至2位
        } else {
          return numPart + "." + floatPart;
        }
      } else {
        return intPartFormat + floatPart;
      }
    },
    //日期过滤器
    datefiler(time) {
      if (!time) return "";
      let date = new Date(time);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      return y + "年" + MM + "月";
    }
  }
};
</script>
<style lang="stylus" scoped>
#box {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  // height: 100vh;
  // margin-bottom: 0.5rem;
}

// 头部
.top {
  width: 100%;
  height: 2.46rem;
  background: url('~@/assets/xsqd/myBg.png');
  background-size: 100%;
}

.avator {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.avator-img {
  display: flex;
  margin: 0.59rem 0.32rem 0;
  align-items: center;
}

.avator-img img {
  width: 1.47rem;
  height: 1.47rem;
  border-radius: 50%;
}

.avator-img .empInfo {
  font-size: 0.43rem;
  font-weight: bold;
  color: #333333;
  margin-left: 0.29rem;
}

.avator-img .empno {
  font-size: 0.32rem;
  font-weight: normal;
  color: #888888;
}

.avator-top {
  margin-right: 0.32rem;
  margin-top: -0.15rem;
}

// 保费
.qdPremium {
  margin: 0 auto;
  width: 9.36rem;
  height: 3.23rem;
  background: #FFFFFF;
  border-radius: 0.2rem;

  .itemIcon {
    width: 0.19rem;
    height: 0.13rem;
    vertical-align: middle;
  }

  .premiumTime {
    margin-left: 0.35rem;
    padding-top: 0.35rem;
  }

  .premium {
    margin-top: 0.56rem;
    display: flex;
    justify-content: center;
  }

  .premiumCon {
    flex: 1;
    text-align: center;
  }

  .separate {
    width: 0.02rem;
    height: 1.23rem;
    background-color: #9d9d9d;
  }

  .premiumAmount {
    font-size: 0.68rem;
    font-weight: bold;
    color: #FF7326;
  }

  .textTip {
    font-size: 0.4rem;
    color: #535353;
  }
}

// 我的订单
.qdOrder {
  margin: 0.32rem auto;
  width: 9.36rem;
  // height: 3.04rem;
  background: #FFFFFF;
  border-radius: 0.2rem;

  .qdOrderTitle {
    height: 0.98rem;
    font-size: 0.4rem;
    font-weight: bold;
    color: #333333;
    line-height: 0.98rem;
    padding-left: 0.33rem;
    border-bottom: 1px solid #E0E0E0;
  }

  .qdOrderCon {
    width: 100%;
    display: flex;
    // padding-left: 0.2rem;
    padding: 0.2rem 0 0.2rem 0.2rem;
    flex-wrap: wrap;
    align-items: center;

    .qdOrderItem {
      // display: flex;
      display: inline-block;
      width: 16%;
      // width: 1.7rem;
      text-align: center;
      margin: 0.2rem 0.4rem;

      img {
        width: 0.85rem;
        height: 0.85rem;
      }

      .textName {
        font-size: 0.35rem;
        font-weight: 400;
        color: #535353;
      }
    }
  }
}

// 底部导航条
.footer {
  background-color: #fff;
  width: 100%;
  height: 1.4rem;
  border-top: 1px solid #e0e0e0;
  position: fixed;
  bottom: 0;
  z-index: 9;
}

.footer>ul {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer ul li {
  width: 33%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.footer ul li span {
  font-size: 0.35rem;
  color: #6b6b6b;
}

.footer ul li i {
  font-size: 1rem;
  color: #6b6b6b;
}

.noMes {
  width: 100%;
  height: auto !important;
  text-align: center;
}

.noMes img {
  width: 5rem;
}

.heightStyle {
  width: 0.72rem;
  height: 0.72rem;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.hotSall {
  background-image: url('~@/assets/image/product_unselected.png');
}

.product {
  background-image: url('~@/assets/image/product_unselected.png');
}

.mine {
  background-image: url('~@/assets/image/my_selected.png');
}

.selectTime /deep/.title-bar-left, .selectTime /deep/.title-bar-right {
  font-size: 0.43rem !important;
}

.selectTime /deep/.column-item {
  font-size: 0.4rem !important;
}

@media (min-width: 320px) and (max-width: 375px) {
  .qd {
    margin-bottom: 1.5rem;
  }
}
</style>